<template>
	<modal name="ClvModalCloverParams" class="ClvModalCloverParams" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="clv_modal">
			<div class="clv_modal_head">
				<div class="clv_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="clv_modal_title">
					<span v-if="clvType == 'addCloverParameter'">Добавление параметра</span>
					<span v-else-if="clvType == 'editCloverParameter'">Редактирование параметра</span>
					<span v-else-if="clvType == 'deleteCloverParameter'">Удаление параметра</span>
				</div>
			</div>
			<div class="clv_modal_body">
				<div class="clv_modal_info">
					<div v-if="['addCloverParameter', 'editCloverParameter'].includes(clvType)">
						<div class="clv_modal_info_row">
							<div class="clv_modal_info_row_label">key:</div>
							<div class="clv_modal_info_row_value" v-if="clvCloverParameterMKeys.includes(clvCloverParameterKey)"><div class="clv_modal_info_row_value_text">{{ clvCloverParameterKey }}</div></div>
							<div class="clv_modal_info_row_value" v-else>
								<input type="text" class="clv_input" v-model.trim="clvCloverParameterKey" placeholder="укажите ключ параметра" autocomplete="off">
								<div v-html="clvAlertCloverParameterKey"></div>
							</div>
						</div>
						<div class="clv_modal_info_row">
							<div class="clv_modal_info_row_label">type:</div>
							<div class="clv_modal_info_row_value">
								<v-select class="clv_select style-chooser" v-model.trim="clvCloverParameterType" placeholder="выберите тип параметра" :options="(clvCloverParameterMKeys.includes(clvCloverParameterKey) ? clvCloverParameterTypes2 : clvCloverParameterTypes1)"></v-select>
								<div v-html="clvAlertCloverParameterType"></div>
							</div>
						</div>
						<div class="clv_modal_info_row">
							<div class="clv_modal_info_row_label">start_value:</div>
							<div class="clv_modal_info_row_value">
								<input type="text" class="clv_input" v-model.trim="clvCloverParameterStartValue" placeholder="укажите стартовое значение параметра" autocomplete="off">
								<div v-html="clvAlertCloverParameterStartValue"></div>
							</div>
						</div>
						<div class="clv_modal_info_row">
							<div class="clv_modal_info_row_label">end_value:</div>
							<div class="clv_modal_info_row_value">
								<input type="text" class="clv_input" v-model.trim="clvCloverParameterEndValue" placeholder="укажите конечное значение параметра" autocomplete="off">
								<div v-html="clvAlertCloverParameterEndValue"></div>
							</div>
						</div>
						<div class="clv_modal_info_row">
							<div class="clv_modal_info_row_label">step_inc:</div>
							<div class="clv_modal_info_row_value">
								<input type="text" class="clv_input" v-model.trim="clvCloverParameterStepInc" placeholder="укажите шаг параметра" autocomplete="off">
								<div v-html="clvAlertCloverParameterStepInc"></div>
							</div>
						</div>
						<div class="clv_modal_info_row">
							<div class="clv_modal_info_row_label">is_test:</div>
							<div class="clv_modal_info_row_value">
								<toggle-button class="clv_toggle_button" :value="toggleValue(clvCloverParameterIsTest)" :sync="true" :width="40" :height="25" :disabled="clvCloverParameterMKeys.includes(clvCloverParameterKey)" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsTest"></toggle-button>
								<div v-html="clvAlertCloverParameterIsTest"></div>
							</div>
						</div>
						<div class="clv_modal_info_row last">
							<div class="clv_modal_info_row_label">is_active:</div>
							<div class="clv_modal_info_row_value">
								<toggle-button class="clv_toggle_button" :value="toggleValue(clvCloverParameterIsActive)" :sync="true" :width="40" :height="25" :disabled="clvCloverParameterMKeys.includes(clvCloverParameterKey)" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsActive"></toggle-button>
								<div v-html="clvAlertCloverParameterIsActive"></div>
							</div>
						</div>
					</div>
					<div v-else-if="clvType == 'deleteCloverParameter'">Вы действительно хотите удалить параметр?</div>
				</div>
				<div class="clv_modal_alert" v-html="clvAlert"></div>
				<div class="clv_modal_loading" :class="{ hide: !clvIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="clv_modal_buttons" :class="{ hide: clvIsLoading }">
					<button type="button" class="clv_button common" @click="close">Закрыть</button>
					<button type="button" class="clv_button common green" @click="axios" v-if="clvType == 'addCloverParameter'">Добавить</button>
					<button type="button" class="clv_button common green" @click="axios" v-else-if="clvType == 'editCloverParameter'">Сохранить</button>
					<button type="button" class="clv_button common red" @click="axios" v-else-if="clvType == 'deleteCloverParameter'">Удалить</button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvTemp: '',
			clvData: {},
			clvAlert: '',
			clvAlertCloverParameterKey: '',
			clvAlertCloverParameterType: '',
			clvAlertCloverParameterStartValue: '',
			clvAlertCloverParameterEndValue: '',
			clvAlertCloverParameterStepInc: '',
			clvAlertCloverParameterIsTest: '',
			clvAlertCloverParameterIsActive: '',
			clvId: 0,
			clvType: '',
			clvIndex: 0,
			clvStrategy: 0,
			clvIsFullAvg: 0,
			clvCloverParameterKey: '',
			clvCloverParameterMKeys: ['date_from', 'date_to'],
			clvCloverParameterType: '',
			clvCloverParameterTypes1: ['boolean', 'integer', 'double', 'string', 'string_tester', 'date'],
			clvCloverParameterTypes2: ['yesterday', '1 week ago', '1 month ago', '2 months ago', '3 months ago', '4 months ago', '5 months ago', '6 months ago', '12 months ago', 'date'],
			clvCloverParameterStartValue: '',
			clvCloverParameterEndValue: '',
			clvCloverParameterStepInc: '',
			clvCloverParameterIsTest: 0,
			clvCloverParameterIsActive: 0,
			clvIsLoading: false,
			clvIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.clvTemp = '';
				this.clvData = {};
				this.resetAlerts();
				this.clvId = event.params.id;
				this.clvType = event.params.type;
				this.clvIndex = event.params.index;
				this.clvStrategy = event.params.strategy;
				this.clvIsFullAvg = event.params.is_full_avg;
				this.clvCloverParameterKey = '';
				this.clvCloverParameterType = '';
				this.clvCloverParameterStartValue = '';
				this.clvCloverParameterEndValue = '';
				this.clvCloverParameterStepInc = '';
				this.clvCloverParameterIsTest = 0;
				this.clvCloverParameterIsActive = 0;
				this.clvIsLoading = false;
				this.clvIsError = false;

				if (this.clvType == 'editCloverParameter') {

					this.clvData = this.$parent.clvCloverParamsData[this.clvIndex];
					this.clvCloverParameterKey = this.clvData.key;
					this.clvCloverParameterType = this.clvData.type;
					this.clvCloverParameterStartValue = this.clvData.start_value;
					this.clvCloverParameterEndValue = this.clvData.end_value;
					this.clvCloverParameterStepInc = this.clvData.step_inc;
					this.clvCloverParameterIsTest = this.clvData.is_test;
					this.clvCloverParameterIsActive = this.clvData.is_active;
				}
			},
			resetAlerts: function() {

				this.clvAlert = '';
				this.clvAlertCloverParameterKey = '';
				this.clvAlertCloverParameterType = '';
				this.clvAlertCloverParameterStartValue = '';
				this.clvAlertCloverParameterEndValue = '';
				this.clvAlertCloverParameterStepInc = '';
				this.clvAlertCloverParameterIsTest = '';
				this.clvAlertCloverParameterIsActive = '';
			},
			getError: function(i) {

				this.clvIsError = true;

				if      (i == 1) { this.clvTemp = 'Необходимо указать id параметра.'; }
				else if (i == 2) { this.clvTemp = 'Необходимо указать верный id параметра.'; }
				else if (i == 3) { this.clvTemp = 'Необходимо указать ключ параметра.'; }
				else if (i == 4) { this.clvTemp = 'Необходимо указать верный ключ параметра.'; }
				else if (i == 5) { this.clvTemp = 'Необходимо выбрать тип параметра.'; }
				else if (i == 6) { this.clvTemp = 'Необходимо выбрать верный тип параметра.'; }
				else if (i == 7) { this.clvTemp = 'Необходимо указать стратегию параметра.'; }
				else if (i == 8) { this.clvTemp = 'Необходимо указать верную стратегию параметра.'; }
				else if (i == 9) { this.clvTemp = 'Необходимо указать стартовое значение параметра.'; }
				else if (i == 10) { this.clvTemp = 'Необходимо указать верное стартовое значение параметра.'; }
				else if (i == 11) { this.clvTemp = 'Необходимо указать конечное значение параметра.'; }
				else if (i == 12) { this.clvTemp = 'Необходимо указать верное конечное значение параметра.'; }
				else if (i == 13) { this.clvTemp = 'Необходимо указать шаг параметра.'; }
				else if (i == 14) { this.clvTemp = 'Необходимо указать верный шаг параметра.'; }
				else if (i == 15) { this.clvTemp = 'Необходимо указать тест параметра.'; }
				else if (i == 16) { this.clvTemp = 'Необходимо указать верный тест параметра.'; }
				else if (i == 17) { this.clvTemp = 'Необходимо указать статус параметра.'; }
				else if (i == 18) { this.clvTemp = 'Необходимо указать верный статус параметра.'; }
				else if (i == 19) { this.clvTemp = 'Необходимо указать FA параметра.'; }
				else if (i == 20) { this.clvTemp = 'Необходимо указать верный FA параметра.'; }
				else if (i == 21) { this.clvTemp = 'Id параметра не найден в системе.'; }
				else if (i == 22) { this.clvTemp = 'Такой ключ параметра уже есть в системе.'; }
				else              { this.clvTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><strong>Ошибка!</strong> ' + this.clvTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					_this.resetAlerts();
					_this.clvIsError = false;

					if (['addCloverParameter', 'editCloverParameter'].includes(_this.clvType)) {
						if (!_this.clvCloverParameterKey) {
							_this.clvAlertCloverParameterKey = _this.getError(3);
						}
						else if (!_this.$store.state.clvRegex_w.test(_this.clvCloverParameterKey)) {
							_this.clvAlertCloverParameterKey = _this.getError(4);
						}
						if (!_this.clvCloverParameterType) {
							_this.clvAlertCloverParameterType = _this.getError(5);
						}
						else if (_this.clvCloverParameterKey && ((!_this.clvCloverParameterMKeys.includes(_this.clvCloverParameterKey) && !_this.clvCloverParameterTypes1.includes(_this.clvCloverParameterType)) || (_this.clvCloverParameterMKeys.includes(_this.clvCloverParameterKey) && !_this.clvCloverParameterTypes2.includes(_this.clvCloverParameterType)))) {
							_this.clvAlertCloverParameterType = _this.getError(6);
						}
						if (!_this.clvIsError) {
							config.url = '/v2/admin/account/clover_params';
							config.data = { id: _this.clvId, key: _this.clvCloverParameterKey, type: _this.clvCloverParameterType, strategy: _this.clvStrategy, start_value: _this.clvCloverParameterStartValue, end_value: _this.clvCloverParameterEndValue, step_inc: _this.clvCloverParameterStepInc, is_test: _this.clvCloverParameterIsTest, is_active: _this.clvCloverParameterIsActive, is_full_avg: _this.clvIsFullAvg };
							config.method = _this.clvType == 'addCloverParameter' ? 'post' : 'patch';
						}
					}
					else if (_this.clvType == 'deleteCloverParameter') {
						config.url = '/v2/admin/account/clover_params';
						config.data = { id: _this.clvId };
						config.method = 'delete';
					}

					if (!_this.clvIsError) {
						_this.clvIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.clvType == 'addCloverParameter') {
									_this.$parent.clvCloverParamsData.push(data.data);
								}
								else if (_this.clvType == 'editCloverParameter') {
									_this.$parent.clvCloverParamsData.splice(_this.clvIndex, 1, data.data);
								}
								else if (_this.clvType == 'deleteCloverParameter') {
									_this.$parent.clvCloverParamsData.splice(_this.clvIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addCloverParameter', 'editCloverParameter', 'deleteCloverParameter'].includes(_this.clvType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.clvAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.clvAlert = _this.getError(2); }
										else if (data.response.data.error == 'KEY_IS_EMPTY') { _this.clvAlertCloverParameterKey = _this.getError(3); }
										else if (data.response.data.error == 'KEY_NOT_VALID') { _this.clvAlertCloverParameterKey = _this.getError(4); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.clvAlertCloverParameterType = _this.getError(5); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.clvAlertCloverParameterType = _this.getError(6); }
										else if (data.response.data.error == 'STRATEGY_IS_EMPTY') { _this.clvAlert = _this.getError(7); }
										else if (data.response.data.error == 'STRATEGY_NOT_VALID') { _this.clvAlert = _this.getError(8); }
										else if (data.response.data.error == 'START_VALUE_IS_EMPTY') { _this.clvAlertCloverParameterStartValue = _this.getError(9); }
										else if (data.response.data.error == 'START_VALUE_NOT_VALID') { _this.clvAlertCloverParameterStartValue = _this.getError(10); }
										else if (data.response.data.error == 'END_VALUE_IS_EMPTY') { _this.clvAlertCloverParameterEndValue = _this.getError(11); }
										else if (data.response.data.error == 'END_VALUE_NOT_VALID') { _this.clvAlertCloverParameterEndValue = _this.getError(12); }
										else if (data.response.data.error == 'STEP_INC_IS_EMPTY') { _this.clvAlertCloverParameterStepInc = _this.getError(13); }
										else if (data.response.data.error == 'STEP_INC_NOT_VALID') { _this.clvAlertCloverParameterStepInc = _this.getError(14); }
										else if (data.response.data.error == 'IS_TEST_IS_EMPTY') { _this.clvAlertCloverParameterIsTest = _this.getError(15); }
										else if (data.response.data.error == 'IS_TEST_NOT_VALID') { _this.clvAlertCloverParameterIsTest = _this.getError(16); }
										else if (data.response.data.error == 'IS_ACTIVE_IS_EMPTY') { _this.clvAlertCloverParameterIsActive = _this.getError(17); }
										else if (data.response.data.error == 'IS_ACTIVE_NOT_VALID') { _this.clvAlertCloverParameterIsActive = _this.getError(18); }
										else if (data.response.data.error == 'IS_FULL_AVG_IS_EMPTY') { _this.clvAlert = _this.getError(19); }
										else if (data.response.data.error == 'IS_FULL_AVG_NOT_VALID') { _this.clvAlert = _this.getError(20); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['editCloverParameter', 'deleteCloverParameter'].includes(_this.clvType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.clvAlert = _this.getError(21); }
									}
								}
								else if (data.response.status == 409) {
									if (['addCloverParameter', 'editCloverParameter'].includes(_this.clvType)) {
										if (data.response.data.error == 'KEY_DUPLICATE') { _this.clvAlert = _this.getError(22); }
									}
								}
							}
							if (!_this.clvIsError) {
								_this.clvAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.clvIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForIsTest: function(event) {
				this.clvCloverParameterIsTest = Number(event.value);
			},
			toggleChangeForIsActive: function(event) {
				this.clvCloverParameterIsActive = Number(event.value);
			},
			close: function() {
				this.$modal.hide('ClvModalCloverParams');
			}
		}
	}
</script>
